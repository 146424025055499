const pages = {
  auth: {
    root: 'auth',
    login: {
      name: 'login',
      path: '/auth/login',
    },
    register: {
      name: 'register',
      path: '/auth/register',
    },
    verifyOtp: {
      name: 'verify-otp',
      path: '/auth/verify-otp',
    },
    createProfile: {
      name: 'create-profile',
      path: '/auth/create-profile',
    },
    forgotPassword: {
      name: 'forgot-password',
      path: '/auth/forgot-password',
    },
    resetPassword: {
      name: 'reset-password',
      path: '/auth/reset-password',
    },
    userRegistered: {
      name: 'user-registered',
      path: '/auth/user-registered',
    },
    authActions: {
      name: 'auth-actions',
      path: '/auth/auth-actions',
    },
  },
  acceptMandate: {
    name: 'accept-mandate',
    path: '/accept-mandate'
  },
  products: {
    name: 'products',
    path: '/products',
  },
  customers: {
    name: 'customers',
    path: '/customers',
  },
  customerProducts: {
    name: 'customer-products',
    path: '/customer-products'
  },
  transactions: {
    name: 'transactions',
    path: '/transactions'
  },
  settings: {
    name: 'settings',
    path: '/settings'
  },
  status: {
    root: 'status',
    unverified: {
      name: 'unverified',
      path: '/status/unverified',
    },
    statusComingSoon: {
      name: 'coming-soon',
      path: '/status/coming-soon',
    },
    statusMaintenance: {
      name: 'maintenance',
      path: '/status/maintenance',
    },
    status404: {
      name: '404',
      path: '/status/404',
    },
    status500: {
      name: '500',
      path: '/status/500',
    },
    statusSuccess: {
      name: 'success',
      path: '/status/success',
    },
    statusFailure: {
      name: 'failure',
      path: '/status/failure',
    },
    statusCancel: {
      name: 'cancel',
      path: '/status/cancel',
    },
  },
};

export default pages;
