import { Logout } from '@mui/icons-material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import MediKreditPoll from 'src/components/mediKreditPoll/MediKreditPoll';
import Icon from 'src/components/SVGIcons';
import TenantSelect from 'src/components/tenantSelect/TenantSelect';
import UserInfo from 'src/components/UserInfo/UserInfo';
import { PAGES } from 'src/config/ability';
import { SidebarContext } from 'src/context/SidebarContext';
import { env } from 'src/env';
import { useAuth } from 'src/features/authentication';
import { AbilityGuard } from 'src/Guards/pageGuard/AbilityGuard';
import pages from 'src/router/routes';
import { useCompanyStore } from 'src/store/company/companyStore';

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const companies = useCompanyStore((state) => state.companies);
  const activeCompany = useCompanyStore((state) => state.activeCompany);
  const resetPracticeState = useCompanyStore((state) => state.resetCompanyState);
  const { logout } = useAuth();

  const handleLogout = () => {
    try {
      logout();
      resetPracticeState();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <List component='div' disablePadding sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Stack sx={{ alignItems: 'center', justifyContent: 'space-evenly' }} direction={'column'} spacing={1}>
          <Typography variant='caption'>v{`${env.VITE_VERSION}`}</Typography>
          <MediKreditPoll />
        </Stack>
      </List>
      <List component='div' disablePadding>
        <List component='div'>
          <ListItem component='div'>
            <TenantSelect practices={companies} />
          </ListItem>
        </List>

        <List
          component='div'
          disablePadding
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '20px',
          }}
        >
          <ListItem disablePadding>
            <ListItemButton
              selected={window.location.pathname === `${pages.products.path}`}
              component={RouterLink}
              onClick={closeSidebar}
              to={pages.products.path}
            >
              <ListItemIcon>
                <Icon name='ShoppingCartIcon' />
              </ListItemIcon>
              <ListItemText primary=' Products' />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              selected={window.location.pathname === `${pages.customers.path}`}
              component={RouterLink}
              onClick={closeSidebar}
              to={pages.customers.path}
            >
              <ListItemIcon>
                <Icon name='UsersIcon' />
              </ListItemIcon>
              <ListItemText primary=' Customers' />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              selected={window.location.pathname === `${pages.customerProducts.path}`}
              component={RouterLink}
              onClick={closeSidebar}
              to={pages.customerProducts.path}
            >
              <ListItemIcon>
                <Icon name='UsersIcon' />
              </ListItemIcon>
              <ListItemText primary=' Customer Products' />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              selected={window.location.pathname === `${pages.transactions.path}`}
              component={RouterLink}
              onClick={closeSidebar}
              to={pages.transactions.path}
            >
              <ListItemIcon>
                <Icon name='UsersIcon' />
              </ListItemIcon>
              <ListItemText primary=' Transactions' />
            </ListItemButton>
          </ListItem>
        </List>

        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
          }}
        >
          <List
            component='div'
            sx={{
              paddingLeft: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <AbilityGuard i={'visit'} a={PAGES.MANAGE_PRACTICE}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={window.location.pathname === `${pages.settings.path}`}
                  component={RouterLink}
                  onClick={closeSidebar}
                  to={pages.settings.path}
                >
                  <ListItemIcon>
                    <SettingsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary='Settings' />
                </ListItemButton>
              </ListItem>
            </AbilityGuard>
            <ListItem component='div' disablePadding>
              <Box textAlign='center' sx={{ width: '220px' }}>
                <UserInfo />
                <Typography>{activeCompany?.role?.name}</Typography>
              </Box>
            </ListItem>
            <ListItem component='div' disablePadding>
              <Box sx={{ width: '220px', paddingTop: '16px' }}>
                <Button
                  sx={{ width: '100%' }}
                  variant='contained'
                  disableRipple
                  onClick={handleLogout}
                  endIcon={<Logout />}
                >
                  Logout
                </Button>
              </Box>
            </ListItem>
          </List>
        </Box>
      </List>
    </>
  );
}

export default SidebarMenu;
