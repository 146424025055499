import { FC, lazy, Suspense } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import AuthGuard from 'src/Guards/authGuard/AuthGuard';
// import { PracticeGuard } from 'src/Guards/practiceGuard/PracticeGuard';
import BaseLayout from 'src/layouts/BaseLayout';
import SidebarLayout from 'src/layouts/SidebarLayout';
import Unverified from 'src/pages/Fallbacks/Status/Unverified/Unverified';
import pages from './routes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loader = (Component: FC) => (props: any) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages
const Products = Loader(lazy(() => import('src/pages/Products/Products')));
const Customers = Loader(lazy(() => import('src/pages/Customers/Customers')));
const Settings = Loader(lazy(() => import('src/pages/Settings/Settings')));
const AcceptMandatePage = Loader(lazy(() => import('src/pages/AcceptMandate/AcceptMandate')));
const CustomerProductsPage = Loader(lazy(() => import('src/pages/CustomerProducts/CustomerProducts')));
const TransactionsPage = Loader(lazy(() => import('src/pages/Transactions/Transactions')));

//Auth
const Register = Loader(lazy(() => import('src/pages/Register/Register')));
const Login = Loader(lazy(() => import('src/pages/Login/Login')));
const VerifyOtp = Loader(lazy(() => import('src/pages/VerifyOTP/VerifyOtp')));
const CreateProfile = Loader(lazy(() => import('src/pages/CreateProfile/CreateProfile')));
const ForgotPassword = Loader(lazy(() => import('src/pages/ForgotPassword/ForgotPassword')));
const ResetPassword = Loader(lazy(() => import('src/pages/ResetPassword/ResetPassword')));
const UserRegistered = Loader(lazy(() => import('src/pages/UserRegistered/UserRegistered')));
const AuthActions = Loader(lazy(() => import('src/pages/AuthActions/AuthActions')));

// Status
const Status404 = Loader(lazy(() => import('src/pages/Fallbacks/Status/Status404/Status404')));
const Status500 = Loader(lazy(() => import('src/pages/Fallbacks/Status/Status500/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/pages/Fallbacks/Status/ComingSoon/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/pages/Fallbacks/Status/Maintenance/Maintenance')));
const StatusSuccess = Loader(lazy(() => import('src/pages/Fallbacks/Status/Success/Success')));
const StatusFailure = Loader(lazy(() => import('src/pages/Fallbacks/Status/Failure/Failure')));
const StatusCancel = Loader(lazy(() => import('src/pages/Fallbacks/Status/Cancel/Cancel')));

/**
 * Configuration for the application's routes using react-router-dom.
 * Routes are organized into different layouts, each specifying the structure
 * and access control for its children. AuthGuard and AbilityGuard are used to
 * control access based on authentication and user abilities (CASL), respectively.
 */
const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    /**
     * All children within this element will NOT have a Sidebar and top Navbar
     * All children within this element does not need to be authenticated to access
     */
    children: [
      //#region Base
      {
        //Navigate to home when base routed to base path
        path: '/',
        element: <Navigate to={pages.products.name} replace />,
      },
      //#endregion Base
      //#region Auth
      {
        //All authentication routes
        //No navbars are shown as the user is not logged in
        path: pages.auth.root,
        children: [
          {
            path: '',
            element: <Login />,
          },
          {
            path: pages.auth.login.name,
            element: <Login />,
          },
          {
            path: pages.auth.register.name,
            element: <Register />,
          },
          {
            path: pages.auth.verifyOtp.path,
            element: <VerifyOtp />,
          },
          {
            path: pages.auth.createProfile.path,
            element: <CreateProfile />,
          },
          {
            path: pages.auth.forgotPassword.path,
            element: <ForgotPassword />,
          },
          {
            path: pages.auth.resetPassword.path,
            element: <ResetPassword />,
          },
          {
            path: pages.auth.userRegistered.path,
            element: <UserRegistered />,
          },
          {
            path: pages.auth.authActions.path,
            element: <AuthActions />,
          },
        ],
      },
      //#endregion Auth
      //#region Status
      {
        //All status routes
        path: pages.status.root,
        children: [
          {
            path: '',
            element: <Navigate to='404' replace />,
          },
          {
            path: pages.status.unverified.name,
            element: <Unverified />,
          },
          {
            path: pages.status.status404.name,
            element: <Status404 />,
          },
          {
            path: pages.status.status500.name,
            element: <Status500 />,
          },
          {
            path: pages.status.statusMaintenance.name,
            element: <StatusMaintenance />,
          },
          {
            path: pages.status.statusComingSoon.name,
            element: <StatusComingSoon />,
          },
          //TODO: Maybe make paths that shows status within the sidebars as well
          {
            path: pages.status.statusSuccess.name,
            element: <StatusSuccess />,
          },
          {
            path: pages.status.statusFailure.name,
            element: <StatusFailure />,
          },
          {
            path: pages.status.statusCancel.name,
            element: <StatusCancel />,
          },
        ],
      },
      //#endregion Status
      //#region NotFound
      {
        path: '*',
        element: <Status404 />,
      },
      //#endregion NotFound
      {
        path: pages.acceptMandate.name,
        element: <AcceptMandatePage />,
      },
    ],
  },
  {
    path: '',
    element: (
      /**
       * All children with this element will have a Sidebar and top Navbar
       * AuthGuard checks that the user is logged in before granting access to its children pages
       */
      <AuthGuard>
        <SidebarLayout />
      </AuthGuard>
    ),
    children: [
      //#region Base
      {
        path: '',
        element: <Navigate to={pages.products.name} replace />,
      },
      //#endregion Base
      //#region Products
      {
        path: pages.products.name,
        element: (
          // <AbilityGuard i={'visit'} a={PAGES.PRODUCTS} showFallback>
          <Products />
          // </AbilityGuard>
        ),
      },
      //#endregion
      //#region Customers
      {
        path: pages.customers.name,
        element: (
          // <AbilityGuard i={'visit'} a={PAGES.PRODUCTS} showFallback>
          <Customers />
          // </AbilityGuard>
        ),
      },
      //#endregion
      {
        path: pages.customerProducts.name,
        element: (
          // <AbilityGuard i={'visit'} a={PAGES.PRODUCTS} showFallback>
          <CustomerProductsPage />
          // </AbilityGuard>
        ),
      },
      //#region Transactions
      {
        path: pages.transactions.name,
        element: (
          // <AbilityGuard i={'visit'} a={PAGES.PRODUCTS} showFallback>
          <TransactionsPage />
          // </AbilityGuard>
        ),
      },
      //#endregion

      //#region Settings
      {
        path: pages.settings.name,
        element: (
          // <AbilityGuard i={'visit'} a={PAGES.PRODUCTS} showFallback>
          <Settings />
          // </AbilityGuard>
        ),
      },
      //#endregion
    ],
  },
];

export default routes;
