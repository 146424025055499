/**
 * Defines the color schemes for the light mode and dark mode.
 */
export const lightModeColors = {
  primary: {
    main: '#2F3192',
    tint: '#E5E6FF',
    shade: '#00024D',
  },
  primaryAlt: {
    main: '#805158',
    tint: '#E5E6FF',
    shade: '#00024D',
  },
  secondary: {
    main: '#28D288',
    tint: '#E5FFF4',
    shade: '#008048',
  },
  tertiary1: {
    main: '#E24678',
    tint: '#FFCCDC',
    shade: '#99264B',
  },
  tertiary2: {
    main: '#FF7C5F',
    tint: '#FDD8D0',
    shade: '#891F08',
  },
  tertiary3: {
    main: '#FFBA54',
    tint: '#FFE3B9',
    shade: '#6E4100',
  },
  success: '#58DD5E',
  warning: '#FFBA17',
  error: '#F92C2C',
  info: '#E5E6FF',
  black: '#000000',
  white: '#ffff',
  trueWhite: '#ffffff',
  uiSurface: '#f9fbfc',
  backGround: '#F9F9FB;',
};

// Subject to change
export const darkModeColors = {
  primary: {
    main: '#2F3192',
    tint: '#4546a1',
    shade: '#1d1e69',
  },
  primaryAlt: {
    main: '#805158',
    tint: '#967378',
    shade: '#58363a',
  },
  secondary: {
    main: '#28D288',
    tint: '#4fdc9e',
    shade: '#1a7d5a',
  },
  tertiary1: {
    main: '#E24678',
    tint: '#e67094',
    shade: '#a93156',
  },
  tertiary2: {
    main: '#FF7C5F',
    tint: '#ff9980',
    shade: '#b25442',
  },
  tertiary3: {
    main: '#FFBA54',
    tint: '#ffca74',
    shade: '#b27e36',
  },
  success: '#58DD5E',
  warning: '#FFE6AF',
  error: '#FFBCB1',
  info: '#E5E6FF',
  black: '#E4E4E4', // Light grey for text on dark backgrounds
  white: '#1a1a1a', // Dark grey for backgrounds
  trueWhite: '#ffffff', // For elements that should stay white
  uiSurface: '#121212', // A darker shade for UI elements
  backGround: '#0D0D0D', // Very dark grey for main background
};